.loader-container {
  display: flex;
  flex-direction: column;
  width: max-content;
  justify-content: center;
  align-items: center;
  &.fullWidth {
    width: 100%;
  }
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;

  svg {
    transform: scale(2) !important; // this is hack for zooming svg into container
    path[fill="rgb(45,156,219)"] {
      fill: var(--primary-color);
    }
  }

  .loading-text {
    color: var(--primary-color);
    font-weight: 600;
    margin-left: 12px;
  }
}
