button.btn {
  box-sizing: border-box;
  transition: initial;
  display: flex;
  align-items: center;
}

button.primary-bg {
  background-color: var(--primary-color);
  &:hover {
    opacity: 0.5 !important;
    color: #fff;
  }
  &.disabled-button {
    opacity: 0.5 !important;
  }
}

button.secondary-bg {
  background-color: var(--primary-pale-color);
  &.active {
    background-color: var(--primary-color);
    .button-text {
      color: #fff;
    }
  }
  &:hover {
    opacity: 0.5;
  }
  &.disabled-button {
    opacity: 0.5 !important;
  }
  &:focus {
    outline: none;
  }

  svg {
    fill: var(--primary-color);
  }
  .button-text {
    color: var(--primary-color);
  }
}

button.regular-btn {
  background-color: var(--regular-button-background);
  .button-text {
    color: #ffffff;
  }
  &:hover {
    background-color: var(--regular-button-hover);
    color: #fff;
  }
  &.disabled-button {
    background-color: var(--regular-button-disabled);
  }
  &:focus {
    outline: none;
    border: 2px solid var(--regular-button-border);
  }
}

button.btn.border-btn {
  border: 2px solid;
}

button {
  background-color: transparent;
  border: none;
}
button.btn {
  border-radius: 50px;
  color: white;
  padding: 10px 26px;
  transition: 0.3s;
}

button.btn-danger {
  background-color: var(--danger-button-background) !important;
  &:hover {
    background-color: var(--danger-button-hover) !important;
  }
  &.disabled-button {
    background-color: var(--danger-button-disabled) !important;
  }
  &:focus {
    outline: none;
    border: 2px solid var(--danger-button-border) !important;
  }
}

button.btn.small-btn {
  padding: 5px 10px;
  .button-text {
    font-size: 14px;
  }
}

button.btn.big-btn {
  padding: 10px 30px;
  .button-text {
    font-size: 22px;
  }
}

button .loader-wrapper {
  svg path {
    fill: #ffffff !important;
  }
}

.round-icon-button {
  margin-right: 12px;
  border: 2px solid var(--primary-color);
}

button.transparent-btn {
  color: var(--text-color);
  &:hover {
    color: var(--text-color);
    opacity: 0.5;
  }
}

.reconnect-email-icon {
  height: 20px;
  width: 20px;
  margin-right: 12px;
}

.chat-sync-button {
  font-size: 20px;
  circle {
    fill: var(--primary-color);
  }
}

.ctd-check-status {
  color: var(--primary-color) !important;
}

.cancel-status-check {
  color: var(--primary-color) !important;
}
