/* global */
/* ------ */
* {
  margin: 0;
  padding: 0;
  outline: 0;
}
html,
body {
  width: 100%;
  height: 100%;
  font-family: "Inter", sans-serif;
}
svg {
  fill: currentColor;
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none !important;
}
/* end global */

/* typography */
/* ---------- */
p,
h1,
h2,
h3 {
  margin: 0;
}
h1 {
  padding: 0;
  color: currentColor;
}
h2 {
  font-weight: 500;
  font-size: 22px;
}
h2.white {
  color: white;
}
h3.subtitle {
  font-size: 12px;
  font-weight: 500;
}
.subtitle.black {
  color: black;
}
.subtitle.f-size-16 {
  font-size: 16px;
}
.f-size-16 {
  font-size: 16px;
}
/* end typography */

/* width height style */
.flex-1 {
  flex: 1;
}
.w-8 {
  width: 8%;
}
.w-10 {
  width: 10%;
}
/* ------------------ */
.w-38 {
  width: 38%;
}
.w-62 {
  width: 62%;
}
/* end width height */

/* global elements style */
/* --------------------- */
html,
body {
  background-color: var(--surface-color);
}
section {
  margin-top: 40px;
}
section.section--modifier {
  border-radius: 15px;
}
.padding-left-50 {
  padding-left: 50px !important;
}
.info-icon {
  width: 25px;
  height: 25px;
  box-sizing: content-box;
  padding: 5px;
}
.icon-circle {
  border-radius: 50px;
  background-color: var(--primary-pale-color);
}
.padding-right {
  padding-right: 40px;
}
.padding-right--modifier {
  padding-right: 60px;
}
.subtitle-wrapper {
  padding: 48px 0;
  border-radius: 15px 15px 0 0;
  background-color: #282828;
  box-shadow: 0px 3px 6px #4a75a17a;
  position: relative;
}
.subtitle-wrapper.subtitle-campaigns--modifier {
  padding: 20px 0;
}
.subtitle-wrapper.subtitle-wrapper--modifier {
  padding: 32px 0;
}
input {
  background-color: transparent;
  border: 0;
}
a {
  color: currentColor;
}
a:hover {
  text-decoration: none;
  color: currentColor;
}
.wrapper-buttons {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 80px;
}
.wrapper-buttons.buttons--modifier {
  margin: 0;
  padding: 25px 26px;
}

.user-info {
  position: absolute;
  top: 3.8%;
  right: 3.8%;
}
.user-info svg {
  width: 20px;
  height: 20px;
}
.not-yet {
  padding-top: 32px !important;
}

.dark-text {
  color: #232b66;
}

body#app-content-body.theme-1
  .input-range-critical
  .input-range__slider-container:nth-child(2)
  .input-range__slider,
body#app-content-body.theme-2
  .input-range-critical
  .input-range__slider-container:nth-child(2)
  .input-range__slider,
body#app-content-body.theme-1 .input-range-critical .input-range__slider,
body#app-content-body.theme-2 .input-range-critical .input-range__slider {
  background-color: var(--error-color) !important;
}

body#app-content-body.theme-1 .input-range-critical .input-range__track--active,
body#app-content-body.theme-2 .input-range-critical .input-range__track--active {
  background: var(--error-color) !important;
}

body#app-content-body .input-range-warmup .input-range__track--active,
body#app-content-body
  .input-range-warmup
  .input-range__slider-container:nth-child(2)
  .input-range__slider,
body#app-content-body .input-range-warmup .input-range__slider {
  background: var(--warning-color) !important;
}

body#app-content-body .input-range__slider,
body#app-content-body .input-range__track--active {
  background-color: var(--primary-color) !important;
}

/* Subtitle list */
/* -------- */
.date-row {
  min-width: 11%;
}
.name-row {
  min-width: 30%;
}
.date-row.modifier {
  min-width: 40%;
  display: flex;
}
.w-20 {
  min-width: 20% !important;
}
.w-40 {
  min-width: 40% !important;
}
.w-60 {
  min-width: 60% !important;
}
.icons-rows {
  width: 30%;
}
.icons-rows.modifier {
  min-width: 40%;
}
.color {
  color: #1b1d48 !important;
}
.label {
  font-size: 12px;
}
.icons-rows .check {
  color: #1c1b1b;
}
.icons-rows .times {
  color: #bdbdbd;
}
.icons-rows span {
  min-width: 25%;
}
.icons-rows li {
  min-width: 25%;
  position: relative;
}
.icons-rows li:hover .desc-text {
  opacity: 1;
}
.icons-rows li .desc-text {
  min-width: auto;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  padding: 4px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.6s;
  transform: translateY(-100%);
  border: 1px solid #282828;
}

.text-rows {
  width: 59%;
}
.text-rows.modifier {
  width: 60%;
}
.text-rows.modifier1 {
  width: 60%;
}
.text-rows.modifier li {
  width: 25%;
}
.text-rows.modifier1 li {
  min-width: 25%;
}
.text-rows li {
  width: 16.5%;
  padding: 0 4px;
}
.radius-50 {
  border-radius: 50px;
}
.circle {
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
}
// .dark-bg {
//   background-color: #7282a0;
//   padding: 8px 20px;
// } #to_be_deleted
.light-bg {
  background-color: #e0e0e0;
  padding: 10px;
}

.wrp-campaigns-list {
  border-radius: 0 0 15px 15px;
}
.campaigns-list {
  border-bottom: 1px solid rgba(128, 128, 128, 0.46);
}
.campaigns-list:last-child {
  border-bottom: none;
  /* padding-bottom: 40px !important; */
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

.form-control.is-valid,
.was-validated .form-control:valid,
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #ced4da;
  background: #fff;
}

.crisp-client {
  z-index: 5000;
  position: relative;
}

.dropdown-menu a:hover {
  color: var(--primary-color);
}

*::-webkit-scrollbar {
  width: 8px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-animation: autofill 0s forwards;
  animation: autofill 0s forwards;
}

@keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

@-webkit-keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

.dropdown-delete-button:hover {
  background-color: rgba(238, 5, 53, 0.15) !important;
}

body#app-content-body.theme-1 button.btn.primary-bg.add-leads-button {
  border-radius: 12px !important;
  padding: 5px 10px;
  .button-text {
    font-size: 14px;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 6px dashed;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  color: var(--primary-color);
}

.caret.caret-down {
  content: "";
  border-top: 0;
  border-bottom: 6px dashed;
}

.tooltip {
  transition: 0.4s;
  border-radius: 5px;
  background-color: var(--on-surface-color);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 4px;
  color: var(--text-colorTooltip);
  top: 0;
  transform: translateY(-110%);
  display: none;
}

.hover-tooltip .tooltip {
  display: none;
}
.hover-tooltip:hover .tooltip {
  opacity: 1;
  display: block;
}

.hover-tooltip:not(.no-tooltip-caret) .tooltip:before {
  content: "";
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 16px solid var(--on-surface-color);
  position: absolute;
  bottom: -15px;
}

.wrp-tooltip button:hover + .tooltip,
.wrp-tooltip .form-group:hover + .tooltip {
  display: block;
  opacity: 1;
}

.search-icon {
  width: 30px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.logout-link {
  color: var(--text-color);
  &:hover {
    color: var(--primary-color);
    svg {
      fill: var(--primary-color);
    }
  }
  cursor: pointer;
}

svg.back-lead-icon {
  width: 40px;
  height: 40px;
}

.timezone-picker {
  height: 38px;
}

.timezone-picker input {
  padding: 0 !important;
  border: 0 !important;
}

.form label {
  font-weight: bold;
  color: #000;
  font-size: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

iframe {
  border: 0;
  width: 100%;
  height: max-content;
}
button.btn .button-text {
  font-weight: 400 !important;
}

.create-tag-icon-selected {
  display: flex;
  width: 12px;
  height: 12px;
  fill: #fff;
}

.add-image-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

button.btn.add-image-btn {
  float: right;
}

.delete-image-template {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 15px;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

.template-image-item:hover ~ .delete-image-template,
.delete-image-template:hover {
  display: flex;
  border-color: var(--primary-color);
}

.icon-step-close-image {
  width: 9px;
  height: 9px;
  color: white;
}

.delete-item-bin-container {
  color: #eb2158;
  font-weight: bold;
}

.font-semi-bold {
  font-weight: 500;
}

.step-2-campaigns {
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
}
.create-campaign-info-buttons {
  display: flex;
  justify-content: flex-end;
}

.wrp-tooltip {
  position: relative;
}

.html-message-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: unset;
}

.email-provider-sub-title {
  padding-left: 2rem;
}

.email-provider-connect-wrapper .email-integrations-wrapper .email-integration-wrapper {
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.email-provider-connect-wrapper .email-integration-wrapper .btn.btn-danger {
  width: 181px;
}

.link-to-email-settings {
  color: var(--primary-color);
  margin: 0 auto 0 10%;
}

.custom-toolbar-caret {
  position: absolute;
  bottom: -7px;
  border-width: 9px 9px 0px 9px;
  border-style: solid;
  border-color: var(--border-color) transparent;
}

.custom-toolbar-caret:before {
  content: "";
  position: absolute;
  bottom: -3px;
  left: -12px;
  border-top: 12px solid transparent;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}

.custom-toolbar-caret:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: -11px;
  border-top: 11px solid transparent;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
}

.caret-top {
  position: absolute;
  top: -7px;
  transform: rotate(180deg);
}

.helper-tooltip {
  position: relative;
  display: inline-block;
  .helper-hover-text {
    display: none;
  }
}

.helper-tooltip:hover {
  .helper-hover-text {
    display: inline-block;
    z-index: 12000;
  }
}

.helper-tooltip .helper-hover-text {
  visibility: hidden;
  width: 135px;
  background-color: var(--on-surface-color);
  color: var(--text-colorTooltip);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1100;
  bottom: 115%;
  left: 60%;
  margin-left: -67px;
  font-size: 13px;
}

.helper-tooltip .helper-hover-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--on-surface-color) transparent transparent transparent;
}

.helper-tooltip:hover .helper-hover-text {
  visibility: visible;
}

.white-helper-tooltip .helper-hover-text {
  visibility: hidden;
  width: 225px;
  background-color: #ffffff;
  color: #080808;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -84px;
  left: 16%;
  margin-left: -67px;
  font-size: 13px;
  border: 1px solid #e1eaf5;
  text-align: start;
  padding: 9px;
  cursor: pointer;
}

.white-helper-tooltip:hover .helper-hover-text {
  visibility: visible;
}

.white-helper-tooltip .helper-hover-text::before {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 109px;
  border-width: 9px 9px 0px 9px;
  border-style: solid;
  border-color: #e1eaf5 transparent;
}

.white-helper-tooltip .helper-hover-text::after {
  content: "";
  position: absolute;
  top: 85px;
  left: 113px;
  margin-left: -3px;
  border-width: 8px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.white-helper-tooltip:hover .helper-hover-text {
  visibility: visible;
}

.ql-container.ql-snow .ql-tooltip {
  left: 0 !important;
  right: unset !important;
  background-color: var(--background-color);
  color: var(--text-color);
  input {
    color: var(--text-color);
  }
}

.noselect-text {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

h1 {
  font-size: 34px;
  line-height: 42px;
  font-weight: 500;
}

h1 + p,
.paragraphUnderH1 {
  margin-top: 8px;
}

.font-weight-light {
  color: var(--text-color);
}

.changing-theme-fade *:not(.dark-theme-switch-overlay) {
  transition: background-color 0.7s linear !important;
}
.InputElement {
  color: var(--placeholder-color);
}
.input-on-background {
  .input-group-labels,
  .form-input-maxlength {
    color: var(--text-color);
  }
  input::placeholder {
    color: var(--placeholder-color);
  }

  // InputElement is-empty Input Input--empty

  .input-group {
    background-color: var(--input-on-background);
    // background-color: var(--surface-color);
  }
  .form-control {
    background-color: transparent;
    color: var(--text-color);
    &::placeholder {
      color: var(--placeholder-color);
    }
  }
}

.form-control-on-background {
  border-color: var(--border-color);
  background-color: var(--input-on-surface);
  color: var(--text-color);
  &::placeholder {
    color: var(--placeholder-color);
  }
  &:focus {
    border-color: var(--primary-color);
    background-color: var(--primary-pale-color);
    color: var(--text-color);
  }
}

.form-control-on-surface {
  background-color: var(--input-on-surface);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  &::placeholder {
    color: var(--placeholder-color);
  }
  &:focus {
    border-color: var(--primary-color);
    background-color: var(--primary-pale-color);
    color: var(--text-color);
  }
}

.stripe-input-background {
  .input-group {
    background-color: var(--stripe-input-background) !important;
  }
}

.input-on-surface {
  .input-group-labels,
  .form-input-maxlength {
    color: var(--text-color);
  }

  .input-group {
    background-color: var(--input-on-surface) !important;
  }

  .form-control {
    background-color: var(--input-on-surface) !important;
    color: var(--text-color);

    &::placeholder {
      color: var(--placeholder-color);
    }
  }

  &--disabled {
    .input-group,
    .form-control {
      background-color: var(--border-color) !important;
    }
  }
}

.form label {
  color: var(--text-color);
}

.search-icon-leads {
  fill: var(--primary-color) !important;
}

.no-cursor {
  cursor: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-color);
}

.primary-bg {
  background-color: var(--primary-color);
}

.emoji-mart {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  border-color: var(--border-color) !important;
  #emoji-mart-search-1 {
    border-color: var(--border-color) !important;
    color: var(--text-color) !important;
  }
  .emoji-mart-category-label span {
    background-color: var(--background-color);
  }

  .emoji-mart-search-icon {
    color: var(--text-color) !important;
  }
}

.new-tabs-design {
  .nav-tabs {
    border: unset;
  }
  .nav-link {
    color: var(--text-color);
    font-weight: 500;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    &.active {
      border-color: transparent;
      border-bottom: 3px solid var(--primary-color) !important;
      color: var(--primary-color);
      background-color: var(--primary-pale-color);
    }
    &:not(.active):hover {
      border-color: transparent;
      color: var(--primary-color);
    }
  }
}

.svg-icon {
  fill: var(--primary-color);
}
.primary-color {
  color: var(--primary-color);
}

// fix for bootstraps css
.btn.disabled,
.btn:disabled {
  opacity: 1 !important;
}

*::-webkit-scrollbar-track {
  background-color: var(--primary-pale-color);
  border-radius: 10px !important;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-color: var(--primary-color) !important;
}

.DateRangePickerInput__withBorder {
  .DateInput_input__focused {
    border-bottom: 2px solid var(--primary-color) !important;
  }

  .CalendarDay__selected_span {
    background: var(--primary-pale-color) !important;
    border-color: var(--primary-color) !important;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
  }
  .DateRangePickerInput_calendarIcon {
    .DateRangePickerInput_calendarIcon_svg {
      fill: var(--primary-color) !important;
    }
  }
}

.checkmark {
  border: 2px solid var(--primary-color) !important;
}

.checkmark-container .checkmark:after,
.checkmark-container-custom-msg .checkmark:after {
  background-color: var(--primary-color);
}

#hidden-loading-animation-container svg path[stroke="rgb(0,0,0)"] {
  stroke: var(--primary-color);
}
#hidden-loading-animation-container {
  max-width: 400px;
}

.rotate-accordion-icon {
  transform: rotate(270deg) !important;
  fill: var(--primary-color) !important;
}

.rotate-accordion-icon-top {
  transform: rotate(90deg) !important;
  fill: var(--primary-color) !important;
}

.connect-accordion-title {
  color: var(--primary-color);
}

.follow-instructions {
  text-decoration: underline;
  color: var(--primary-color);
}

.follow-instructions:hover {
  text-decoration: underline;
  color: var(--primary-color);
}

body#app-content-body.theme-1 input[type="checkbox"].switch:checked + div > div {
  background: var(--primary-color) !important;
}

body#app-content-body.theme-1 input[type="checkbox"].switch:checked + div {
  background-color: var(--primary-pale-color);
}

body#app-content-body.theme-1 .light-blue-bg {
  background-color: #d2e6ff !important;
  .button-text {
    color: var(--primary-color) !important;
  }
}

.form-control,
.form-control:focus {
  background-color: transparent;
  color: var(--text-color);

  &::placeholder {
    color: var(--placeholder-color);
  }

  &:focus-within {
    border-color: transparent;
  }
}
.saved-replies-wrapper {
  .form-control,
  .form-control:focus {
    &:focus-within {
      border: none;
    }
  }
}

.campaign-regular-layout,
.webhook-page-regular-layout {
  padding-bottom: 0 !important;
  .round-icon-button {
    border-color: #fff;
  }
}

.create-campaign-icon {
  width: 28px !important;
  height: 28px !important;
  padding: 4px !important;
  fill: #fff;
}

label {
  margin-bottom: 0;
}

.template-modal-buttons {
  display: flex;
  justify-content: space-evenly;
}

.create-sequence {
  display: flex;

  &__card {
    text-align: center;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    width: 400px;
    margin: 20px;
    padding-bottom: 20px;
  }

  &__icon {
    width: 160px;
    height: 150px;
    margin: 20px 0;
    fill: var(--primary-color);
  }

  &__button {
    margin: 0 auto 15px auto !important;
    border-radius: 10px !important;
  }

  &__text {
    color: var(--text-color);
  }
}

.modal-content {
  background-color: var(--background-color);
  color: var(--text-color);
}

.nav-link-sales-templates {
  padding-bottom: 20px;
}

.nav-link-sales-templates .nav-tabs {
  border: 0;
}

.nav-link-sales-templates .nav-link {
  padding: 0.1rem 0;
  margin-right: 2rem;
  font-weight: 500;
  font-size: 1.3rem;
}

.nav-link-sales-templates .nav-link.active {
  border-color: transparent;
  border-bottom: 3px solid var(--primary-color);
  color: var(--primary-color);
  padding: 0.1rem 0;
  background-color: transparent;
}

.nav-link-sales-templates .nav-link:not(.active):hover {
  border-color: transparent;
  color: var(--primary-color);
}

.title-dropdown-menu.dropdown-menu {
  z-index: 10000;
}

.Toastify__toast-container.Toastify__toast-container--bottom-right {
  bottom: 3.5rem;
}

.Toastify__toast {
  padding: 10px;
}

.tooltipText {
  opacity: 1 !important;
}

.campaign-tooltip {
  position: relative;
  display: inline-block;
  pointer-events: all;
  width: 45px;
}

/* Tooltip text */
.campaign-tooltip .tooltipText {
  visibility: hidden;
  width: 306px;
  background-color: var(--on-surface-color);
  color: var(--background-color);
  padding: 5px 5px;
  border-radius: 6px;
  bottom: 40px;
  left: 62%;
  margin-left: -155px;
  text-align: start;
  font-size: 12px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.campaign-tooltip:hover .tooltipText {
  visibility: visible;
}

.campaign-tooltip .tooltipText::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: var(--on-surface-color) transparent transparent transparent;
}

.dark-tooltip-bottom-caret::after {
  border-top: 11px solid var(--on-surface-color) !important;
}

.light-tooltip-bottom-caret {
  bottom: -9px;
}

.light-tooltip-bottom-caret::after {
  border-top: 11px solid var(--surface-color) !important;
}

.light-tooltip-bottom-caret::before {
  bottom: -4px;
  border-top: 12px solid var(--border-color) !important;
}

/* The container */
.checkmark-container {
  margin-right: 40px;
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.checkmark-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: transparent;
  border-radius: 50%;
  margin-top: 3px;
}

/* On mouse-over, add a grey background color */
.checkmark-container:hover input ~ .checkmark {
  background-color: var(--surface-color);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.checkmark-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.checkmark-container .checkmark:after {
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.no-teams-found {
  color: var(--text-color);
}

.invalid {
  color: #fff;
}
.valid {
  color: var(--primary-color);
}

.close {
  text-shadow: none !important;
  opacity: 1 !important;
  margin-left: 5px;
}

.button-loader {
  display: flex;
  .btn-text {
    margin-left: 10px;
    width: max-content;
    color: #fff;
    display: flex;
    align-items: center;
  }
  .btn-text:hover {
    color: #fff;
  }
}

.create-label-btn {
  width: 100%;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-close {
  position: absolute;
  top: 0;
  right: 7px;
  padding: 1rem;
  color: var(--text-color);
  z-index: 1;

  &__icon {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
  }
}

.dropdown-menu-title {
  font-size: 22px;
  text-align: center;
  display: block;
  border-bottom: 1px solid var(--border-color);
  padding: 5px 0 10px 0;
  margin-bottom: 20px;
}

.wrapper-timezone {
  .timezone-picker-seat-settings {
    input {
      border: none;
      font-size: 16px;
      font-family: "Inter", sans-serif;
      padding: 0;
      color: var(--text-color) !important;
      background-color: transparent !important;
    }
  }

  .timezone-info-box {
    font-size: 15px;
  }
}

.timezone-picker-seat-settings ul {
  border: 1px solid var(--border-color) !important;
  margin-top: 12px !important;
  width: calc(100% + 20px) !important;
  left: -10px !important;
}
.timezone-picker-seat-settings ul,
.timezone-picker-seat-settings li button {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

.timezone-picker-seat-settings li button:hover {
  background-color: var(--primary-pale-color) !important;
}

.no-active-accounts {
  text-align: center;
  font-size: 14px;

  &__text {
    text-align: justify;
    padding-bottom: 30px;
  }

  &__image {
    width: 160px;
    height: auto;
  }

  &__emphasized {
    font-style: italic;
    font-weight: bold;
  }
}

.modal-open {
  .header-container {
    position: static;
  }
}

.info-box-caret {
  top: auto !important;
}
