.help-center-tooltip-label {
  cursor: pointer;
  margin-top: 10px;
  color: var(--primary-color);
}

.help-center-icon {
  width: 17px;
  height: 17px;
  margin-left: 5px;
  fill: var(--primary-color);
}

.helper-hover-text-size {
  font-size: 13px;
}

.help-center-text-color {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary-color);
}
