.time-picker-container {
  width: 100%;
  height: 24px;
}

.time-picker-wrapper {
  position: absolute;
  display: flex;
  height: 100px;
  left: 0;
  width: 100%;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--primary-color);
  border-radius: 12px;
  top: 40px;
  overflow: hidden;
  padding: 2px 3px 0 0;
  z-index: 1;
  max-width: 100px;
  .time-picker-side {
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    .time-picker-select {
      text-align: center;
      font-size: 12px;
      padding: 5px;
      border-radius: 12px;
      cursor: pointer;
      &:hover {
        background-color: var(--primary-pale-color) !important;
      }
    }
  }
}

.time-picker-gray {
  .time-picker-wrapper {
    background-color: #eceef2;
  }
}

.time-picker-text {
  color: var(--text-color);
  font-size: 16px;
  font-weight: normal;
}
.DateInput__disabled,
.DateInput_input__disabled {
  background-color: transparent;
}

.SingleDatePicker.SingleDatePicker_1 {
  width: 100%;
}
