:root {
  --chat-message-footer-color: rgba(225, 231, 241, 0.2);
}
:root .dark-theme {
  --primary-color: #0080FF;
  --primary-pale-color: #415c7a;

  --on-secondary-color: var(--text-color);

  --background-color: #252525;
  --input-on-background: #2d2d2d;

  /* Skylead colors */
  /* 
  --input-on-background: #2e3f57;
  */
  --surface-color: #22272e;
  --on-surface-color: #e6f0ff;

  --text-color: #e6f0ff;
  --text-colorTooltip: #535465;

  --border-color: #40486d;
  --success-color: #3ec786;
  --error-color: #eb2158;
  --warning-color: #f5b555;

  --some-emails-connected: #f37047;

  --lead-list-discovered: #6989ae;
  --lead-list-discovered-color: #fff;
  --lead-list-connection-pending: var(--warning-color);
  --lead-list-connection-pending-color: #fff;
  --lead-list-connected-not-replied: #794f84;
  --lead-list-connected-not-replied-color: #fff;
  --lead-list-replied: var(--success-color);
  --lead-list-replied-color: #fff;

  --lead-list-replied-not-connected: #fbdcea;
  --lead-list-replied-not-connected-color: #e85297;

  --campaign-statistic-count-total-profile-views-background: #7884a6;
  --campaign-statistic-total-replies-background: #73848d;

  --table-rate-gray-background: #c9cad3;
  --table-rate-green-background: #dcfde1;

  --input-on-surface: var(--background-color);
  --placeholder-color: #98abc1;

  --statistics-table-last-row: #8b8781;
  --stripe-input-background: #fff;
  --light-caret-border-color: #fff;
  --leads-count-statistic: #52C462;

  --icon-active-color: #20c997;
  --icon-failed-color: #eb2158;

  --active-email-status: #fff;

  /* TODO: define --auth-background for both themes */
  /* --auth-background: red; */

  /* buttons */

  /* All HSL values are bounded to primary color and represented as difference from primary color. */

  /* Because all shades of HSL colors in files are calculated with subtraction, you can find negative values here. 
  In example, if we need brighter lightness that original color, subtraction of negative value actually gives addition.  */

  /*================ REGULAR ================*/
  --regular-button-background: #617081;
  --regular-button-hover: #4d5c6d;
  --regular-button-disabled: #465261;
  --regular-button-border: #ffffff;
  /*================ end of REGULAR ================*/

  /*================ DANGER ================*/
  --danger-button-background: #d94d69;
  --danger-button-hover: #c43552;
  --danger-button-disabled: #a5455d;
  --danger-button-border: #f9a4b6;
  /*================ end of DANGER ================*/
  /* END OF BUTTONS */
}

:root .light-theme {
  --primary-color: #0080FF;
  --primary-pale-color: #d5eaff;
  --on-secondary-color: var(--primary-color);

  --surface-color: #fafaff;
  --on-surface-color: #535465;

  --background-color: #ffffff;
  --text-color: #535465;
  --text-colorTooltip: #ffffff;

  --border-color: #e1e7f1;
  --success-color: #09ab09;
  --error-color: #eb2158;
  --warning-color: #f5c414;

  --some-emails-connected: #f37047;

  --lead-list-discovered: #e1e7ef;
  --lead-list-discovered-color: #6989ae;

  --lead-list-connected-not-replied: #e4dce6;
  --lead-list-connected-not-replied-color: rgb(121, 79, 132);

  --lead-list-replied: #cff1e6;
  --lead-list-replied-color: rgb(19, 183, 132);

  --lead-list-connection-pending: #fbf0de;
  --lead-list-connection-pending-color: rgb(236, 178, 93);

  --lead-list-replied-not-connected: #fbdcea;
  --lead-list-replied-not-connected-color: #e85297;

  --campaign-statistic-count-total-profile-views-background: #f9f9f9;
  --campaign-statistic-total-replies-background: #dcfde1;

  --table-rate-gray-background: #f0f0f7;
  --table-rate-green-background: #dcfde1;

  --input-on-background: var(--surface-color); /* should be var(--input-on-surface) */

  --input-on-surface: var(--background-color);
  --placeholder-color: #98abc1;

  --statistics-table-last-row: #fffaf1;
  --stripe-input-background: var(--input-on-surface);
  --light-caret-border-color: #e1e7f1;
  --leads-count-statistic: #52C462;

  --icon-active-color: #20c997;
  --icon-failed-color: #eb2158;

  --active-email-status: var(--success-color);

  /* buttons */

  /* All HSL values are bounded to primary color and represented as difference from primary color. */

  /* Because all shades of HSL colors in files are calculated with subtraction, you can find negative values here. 
  In example, if we need brighter lightness that original color, subtraction of negative value actually gives addition.  */

  /*================ REGULAR ================*/
  --regular-button-background: #98abc1;
  --regular-button-hover: #7f8d9e;
  --regular-button-disabled: #b7c4d3;
  --regular-button-border: #cbdbeb;
  /*================ end of REGULAR ================*/

  /*================ DANGER ================*/
  --danger-button-background: #f02750;
  --danger-button-hover: #e10a36;
  --danger-button-disabled: #f46784;
  --danger-button-border: #fcb0bf;
  /*================ end of DANGER ================*/

  /* END OF BUTTONS */
}
