.input-group-wrapper {
  position: relative;
  max-width: 100%;

  textarea {
    &::placeholder {
      color: var(--placeholder-color);
    }
    font-size: 15px;
    font-family: inherit;
  }

  textarea,
  textarea:focus {
    background-color: var(--input-on-background);
    color: var(--text-color);
  }

  &.backgrounded {
    background-color: var(--input-on-background);
    padding: 10px;
    border-radius: 4px;
    .form-group {
      margin: 0 !important;
    }
    .input-group {
      padding: 0;
    }
  }

  .ql-container {
    min-height: 100px;
  }

  .ql-container,
  .ql-toolbar {
    border: 0 !important;
  }

  .ql-toolbar {
    border-bottom: 1px solid var(--border-color) !important;
    svg {
      color: var(--text-color);
      fill: var(--text-color) !important;
      stroke: var(--text-color) !important;
      cursor: pointer;

      .ql-stroke {
        stroke: var(--text-color) !important;
      }
    }
  }

  .ql-picker-label {
    color: var(--text-color);
  }

  .ql-picker.ql-size .ql-picker-label[data-value="0.75em"]::before,
  .ql-picker.ql-size .ql-picker-item[data-value="0.75em"]::before {
    content: "Small";
  }
  .ql-picker.ql-size .ql-picker-item[data-value="0.75em"]::before {
    font-size: 0.75em !important;
  }

  .ql-picker.ql-size .ql-picker-label[data-value="1em"]::before,
  .ql-picker.ql-size .ql-picker-item[data-value="1em"]::before {
    content: "Normal";
  }
  .ql-picker.ql-size .ql-picker-item[data-value="1em"]::before {
    font-size: 1em !important;
  }

  .ql-picker.ql-size .ql-picker-label[data-value="1.5em"]::before,
  .ql-picker.ql-size .ql-picker-item[data-value="1.5em"]::before {
    content: "Large";
  }
  .ql-picker.ql-size .ql-picker-item[data-value="1.5em"]::before {
    font-size: 1.5em !important;
  }

  .ql-picker.ql-size .ql-picker-label[data-value="2.5em"]::before,
  .ql-picker.ql-size .ql-picker-item[data-value="2.5em"]::before {
    content: "Huge";
  }
  .ql-picker.ql-size .ql-picker-item[data-value="2.5em"]::before {
    font-size: 2.5em !important;
  }
}

.input-group-wrapper .search-icon {
  right: 10px;
}

.input-group-wrapper .form-control {
  border-radius: 0;
  border-color: transparent;
  padding: 0;
  margin: 0;
  height: auto;
  z-index: 1000;
}

.input-group-wrapper .input-group {
  width: 100%;
  border-radius: 12px;
  padding: 10px;
  border: 1px solid var(--border-color);
  min-height: 34px;
  display: flex;
  align-items: center;
}

.input-group-wrapper.icon-input .input-group {
  padding: 10px 40px 10px 10px;
}

.input-transparent .input-group {
  background-color: transparent !important;
  width: 100%;
  border-radius: 0;
  border: 0;
  border-bottom: 3px solid var(--text-color) !important;
  padding: 0;
  .form-control {
    background-color: transparent !important;
  }
}

.input-group-wrapper .input-group:focus-within {
  border-color: var(--primary-color) !important;
  & .custom-file-label {
    border-color: transparent !important;
  }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.input-group-icon {
  fill: var(--primary-color);
}

.input-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.error-icon,
.success-icon {
  border-radius: 50%;
  text-align: center;
  font-size: 10px;
  width: 15px;
  font-weight: bold;
  height: 15px;
  display: block;
  color: #fff;
  right: 10px;
  z-index: 1000;
}

.error-icon {
  background-color: var(--error-color);
}

.success-icon {
  background-color: #53c462;
}

.error-message {
  color: var(--error-color);
  display: block;
  margin-top: 5px;
}

.error-message-icon {
  margin-right: 6px;

  .icon-size {
    width: 18px;
    height: 18px;
    margin-bottom: 4px;
    padding: 0;
  }
}

.form-label-small {
  color: var(--text-color);
  font-size: 12px;
}

.form-description {
  color: var(--text-color);
  font-size: 12px;
  line-height: 14px;
  display: flex;
  margin: 10px 0;
}

.form-input-maxlength {
  font-size: 12px;
  line-height: 14px;
  display: block;
  margin-top: 5px;
}

.input-length-exceeded {
  color: #dc3545;
}

.input-gray {
  .input-group {
    background-color: var(--surface-color);

    .form-control {
      background-color: transparent;
    }
  }
}

.chat-email-input {
  width: 100%;
  margin: 0 !important;

  .form-group {
    margin: 0;
  }

  .input-group {
    border: none;
    padding: 0;

    .text-area {
      .ql-container {
        display: flex;
        flex-direction: column;
      }

      .ql-container,
      .ql-snow {
        font-size: inherit;
        font-family: inherit;
      }

      .ql-editor {
        flex: 1;
        max-height: 100px;
        overflow: auto;
        word-break: break-word;
      }

      .ql-blank:before {
        font-style: normal;
      }
    }
  }
}

.expanded {
  .input-group {
    .text-area {
      .ql-editor {
        max-height: calc(100vh - 400px);
      }
    }
  }
}

.input-bordered:not(.email-message-input) {
  .input-group {
    border: 1px solid var(--border-color);
  }
}

.input-bordered:not(.search-saved-replies) .input-group {
  border: 1px solid var(--border-color);
  z-index: 1;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none;
}

.custom-file-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 78px;
  border-radius: 12px;
}

.input-group #card {
  display: grid;
  .__PrivateStripeElement {
    align-self: center;
  }
}

.input-group select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.input-group .timezone-picker input {
  background-color: transparent;
  color: var(--text-color);
}

.input-group .timezone-picker ul {
  background-color: #fff;
}

.input-group .rc-time-picker .rc-time-picker-input {
  background-color: transparent;
}

.rc-time-picker .rc-time-picker-clear {
  top: 0;
  bottom: 0;
  right: -10px;
}

.input-group .rc-time-picker-input {
  padding: 0;
  height: auto;
  font-size: inherit;
  border: 0;
}

.input-group-labels {
  align-items: center;
  margin-bottom: 8px !important;
}

.input-group-append {
  height: 52px;
  margin: -10px -10px -10px 10px;
  .input-group-text {
    border-radius: 0 12px 12px 0;
    border-color: var(--border-color);
    background-color: var(--primary-pale-color) !important;
    color: var(--on-secondary-color);
  }
  z-index: 101;
}

.input-error .input-group {
  border-color: var(--error-color) !important;
}

.ql-picker.ql-font {
  width: 150px !important;
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font {
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font .ql-picker-label[data-value="arial"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="arial"]::before,
.ql-font-arial {
  font-family: Arial, Helvetica, sans-serif;
  content: "Arial" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="couriernew"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="couriernew"]::before,
.ql-font-couriernew {
  font-family: "Courier New", serif;
  content: "Courier New" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="garamond"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="garamond"]::before,
.ql-font-garamond {
  font-family: garamond, "times new roman", serif;
  content: "Garamond" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="georgia"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="georgia"]::before,
.ql-font-georgia {
  font-family: Georgia, serif;
  content: "Georgia" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="helvetica"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="helvetica"]::before,
.ql-font-gelvetica {
  font-family: Helvetica, sans-serif;
  content: "Helvetica" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="impact"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="impact"]::before,
.ql-font-impact {
  font-family: Impact, Charcoal, sans-serif;
  content: "Impact" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="lucida"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="lucida"]::before,
.ql-font-lucida {
  font-family: "Lucida Console", "Courier New", serif;
  content: "Lucida Console" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="timesnewroman"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="timesnewroman"]::before,
.ql-font-timesnewroman {
  font-family: "Times New Roman", Times, serif, -webkit-standard;
  content: "Times New Roman" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="tahoma"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="tahoma"]::before,
.ql-font-tahoma {
  font-family: Tahoma, Geneva, Helvetica, sans-serif;
  content: "Tahoma" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="trebuchet"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="trebuchet"]::before,
.ql-font-trebuchet {
  font-family: "trebuchet ms", Helvetica, sans-serif;
  content: "Trebuchet MS" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="verdana"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="verdana"]::before,
.ql-font-verdana {
  font-family: Verdana, Geneva, Helvetica, sans-serif;
  content: "Verdana" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before,
.ql-font-monospace {
  font-family: monospace;
  content: "Fixed width" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="wide"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="wide"]::before,
.ql-font-wide {
  font-family: "arial black", sans-serif;
  content: "Wide" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="narrow"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="narrow"]::before,
.ql-font-narrow {
  font-family: "arial narrow", sans-serif;
  content: "Narrow" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="comicsansms"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="comicsansms"]::before,
.ql-font-comicsansms {
  font-family: "comic sans ms", sans-serif;
  content: "Comic Sans MS" !important;
}

.expand-icon-email {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  cursor: pointer;
  z-index: 1;
}

.preview-icon {
  width: 20px;
  height: 20px;
  margin-top: 5px;
  cursor: pointer;
  fill: var(--text-color);
  z-index: 1;
}
.quill.form-control {
  background-color: var(--input-on-background);
  color: var(--text-color);

  .ql-editor {
    font-size: initial;

    &:before {
      color: var(--placeholder-color);
    }
  }
}

.input-group-error {
  .input-group,
  .input-group:hover,
  .input-group:focus-within {
    border: 1px solid var(--error-color) !important;
  }
}

.days-error {
  .input-group,
  .input-group:hover,
  .input-group:focus-within {
    border: 1px solid var(--error-color) !important;
  }
}

.hours-error {
  .input-group,
  .input-group,
  .input-group:hover,
  .input-group:focus-within {
    border: 1px solid #dc3545 !important;
  }
}

.DateInput_fang {
  display: none;
}
.DateRangePickerInput_arrow_svg {
  fill: var(--text-color) !important;
}
.DateRangePickerInput {
  border-color: var(--border-color) !important;
}

//PAINT DATEPICKER
.SingleDatePickerInput,
.DateInput,
.DateInput_input,
.DateInput_input__focused,
.DateRangePickerInput,
.SingleDatePicker_picker,
.DayPicker,
.DayPicker_weekHeaders,
.DayPicker_weekHeader,
.DayPicker_focusRegion,
.DayPickerNavigation,
.DayPicker_transitionContainer,
.CalendarMonthGrid,
.CalendarMonthGrid_month__horizontal,
.CalendarMonth,
.CalendarMonthGrid_month__horizontal,
.DayPickerNavigation_button,
.DayPickerKeyboardShortcuts_panel {
  background-color: var(--input-on-background) !important;
  color: var(--text-color) !important;
} //paint whole calendar :(

.DayPickerNavigation_button {
  border-color: var(--border-color) !important;
  &:hover {
    border-color: var(--primary-color) !important;
  }
  svg {
    fill: var(--text-color);
  }
}
.CalendarMonth_caption strong {
  color: var(--text-color);
}

.CalendarDay.CalendarDay__blocked_out_of_range {
  // border-color: 1px solid var(--border-color) !important;
  background-color: var(--background-color);
  &:hover {
    background-color: var(--background-color);
  }
}

.CalendarDay__default:not(.CalendarDay__blocked_out_of_range):not(.CalendarDay__selected):not(
    .CalendarDay__selected_span
  ) {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  &:hover {
    background-color: var(--primary-pale-color) !important;
  }
}

.CalendarDay__selected {
  background: var(--primary-color) !important;
  border: 1px double var(--primary-color) !important;
  color: var(--text-color) !important;
}
.DayPickerKeyboardShortcuts_showSpan {
  color: var(--text-color) !important;
}

//end painting datepicker

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--input-on-background) inset !important;
  border: 0;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: var(--text-color) !important;
}

.input-group:focus-within,
.form-control:focus-within,
.form-control:focus:focus-within {
  outline: none;
}

.search-on-master-login {
  width: 100%;
  background-color: var(--input-on-surface) !important;
}

.input-on-surface {
  color: var(--primary-color);
}

.edit-team {
  height: 25px;
  border-bottom: 2px solid var(--primary-color);
  width: 500px;

  .input-group {
    border: none;
    width: fit-content;
    bottom: 16px;
    padding: 0;
  }

  .form-control {
    font-size: 24px;
    width: 500px;
  }

  .error-message {
    margin-top: -10px;
    font-size: 15px;
    font-weight: lighter;
  }

  .error-icon {
    visibility: hidden;
  }
}

.input-error.edit-team {
  border-bottom: 2px solid var(--error-color);
}

.sender-name-input-group {
  .form-input-maxlength {
    margin-top: 8px;
  }

  .input-group-labels {
    margin-bottom: 0 !important;
  }
}

.password-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.password-icon-error {
  margin-right: 17px;
}
