.loading-page-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 10000000;
  margin: 0;
  background-color: var(--background-color);
}

.loading-page-inner-wrapper {
  svg path[stroke="rgb(0,0,0)"] {
    stroke: var(--primary-color);
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
