.error-message-container {
  display: flex;

  .error-icon-message {
    margin: 5px 10px 0 0;
  }
}

.buttons-container {
  margin-left: 27px;
  display: flex;
  justify-content: center;
}

.disabled-sender-email {
  .form-control {
    background-color: var(--border-color) !important;
  }

  .input-group {
    background-color: var(--border-color) !important;
    opacity: 0.6;
  }
}
